import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';

import useSettings from 'hooks/useSettings';
import useCarousel from 'hooks/useCarousel';
import Logo from './Logo';
import Flex from './Flex';
import Button from './Button';
import Link from './Link';

const HomeHero = ({ leadIn, title, content, images }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, { onlineOrderingUrl }] = useSettings();

  const [current] = useCarousel({ size: images.length });
  const image = images[current];

  return (
    <>
      <div style={{ width: 0, height: 0 }} className="invisible overflow-hidden">
        {images.map((x) => (
          <img key={x} src={x} alt="" className="preload" />
        ))}
      </div>
      <div className="relative">
        <AnimatePresence exitBeforeEnter>
          <motion.img
            key={image}
            src={image}
            className="absolute inset-0 w-full h-full object-cover object-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          />
        </AnimatePresence>
        <div className="absolute inset-0 gradient-horizontal" />
        <div className="container relative">
          <div className="max-w-4xl mx-auto text-center pt-8 pb-24 lg:py-32 text-shadow-lg">
            <Logo className="w-40 mx-auto mb-8 lg:hidden" />
            <h1>
              {leadIn && (
                <span className="block uppercase text-white text-lg lg:text-2xl leading-none">
                  {leadIn}
                </span>
              )}
              {title && (
                <span className="block font-script text-accent leading-none mt-2 text-7xl lg:text-10xl">
                  {title}
                </span>
              )}
            </h1>
            {content && (
              <p className="lg:text-lg text-white leading-loose mt-4 lg:mt-12">{content}</p>
            )}

            <Flex container="flex-col -mt-6 pt-12" item="pt-6">
              <Button href="/menu" className="text-lg">
                View our menu{' '}
                <FontAwesomeIcon icon={faChevronRight} size="1x" className="ml-3 text-accent" />
              </Button>
              {onlineOrderingUrl && (
                <Link
                  href={onlineOrderingUrl}
                  className="text-lg inline-block text-accent lg:hidden"
                >
                  Order online
                </Link>
              )}
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};

HomeHero.propTypes = {
  leadIn: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
};

HomeHero.defaultProps = {
  leadIn: null,
  title: null,
  content: null,
  images: [],
};

export default HomeHero;

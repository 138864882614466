import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CuratorWidget extends Component {
  constructor(props) {
    super(props);
    this.loadScript = this.loadScript.bind(this);
  }

  componentDidMount() {
    const { feedId } = this.props;
    this.loadScript(`https://cdn.curator.io/published/${feedId}.js`, 'curator');
  }

  // eslint-disable-next-line class-methods-use-this
  loadScript(src, libraryId) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.id = libraryId;
      document.body.appendChild(script);

      script.onload = () => {
        setTimeout(() => {
          resolve(true);
        }, 100);
      };
    });
  }

  render() {
    const { feedId } = this.props;
    return <div data-crt-feed-id={feedId} />;
  }
}

CuratorWidget.propTypes = {
  feedId: PropTypes.string.isRequired,
};

export default CuratorWidget;

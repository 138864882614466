import React from 'react';
import PropTypes from 'prop-types';

import Flex from './Flex';

const Special = ({ type, name, price, description }) => (
  <div>
    {type && <p className="uppercase text-sm tracking-wide mb-1">{type}</p>}
    <Flex container="-ml-6" item="pl-6">
      <h3 className="text-xl text-white">{name}</h3>
      <p className="text-xl text-white font-medium">{price}</p>
    </Flex>
    <p className="mt-3 leading-relaxed">{description}</p>
  </div>
);

Special.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  price: PropTypes.string,
};

Special.defaultProps = {
  description: null,
  type: null,
  price: null,
};

export default Special;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import cx from 'classnames';

import Link from './Link';

const formatting = {
  bold: 'font-bold',
  italic: 'italic',
  underline: 'underline',
  strikethrough: 'line-through',
};

export const serializeAsJSX = (node) => {
  if (node.object === 'text') {
    if (!node.marks.length) return node.text;

    const formattingClasses = node.marks.map((x) => {
      const fclassName = formatting[x.type];
      if (!fclassName) console.log(`>>> Class needed? ${x.type}`);
      return fclassName;
    });

    return <span className={cx(formattingClasses)}>{node.text}</span>;
  }

  const children = node.nodes.map((n) => (
    <Fragment key={uniqueId(`editor_${node.type}`)}>{serializeAsJSX(n)}</Fragment>
  ));

  switch (node.type) {
    case 'unordered-list':
      return <ul className="list-disc ml-8">{children}</ul>;

    case 'ordered-list':
      return <ol className="list-decimal ml-8">{children}</ol>;

    case 'list-item':
      return <li>{children}</li>;

    case 'paragraph':
      return <p>{children}</p>;

    case 'link':
      return (
        <Link
          href={node.data.href}
          className="underline hover:no-underline transition-all duration-200"
        >
          {children}
        </Link>
      );

    default:
      if (node.type) console.log(`>>> Renderer needed? ${node.type}`);
      return children;
  }
};

export const serializeAsPlainText = (node) => {
  if (node.text) return node.text;
  if (!node.nodes) return '';
  return node.nodes.map(serializeAsPlainText).join(' ').trim();
};

const EditorContent = ({ content, className, component: Component, serializer }) => {
  const serializedContent = React.useMemo(() => {
    if (!content) return null;

    const ast = JSON.parse(content.document);

    switch (serializer) {
      case 'jsx':
        return serializeAsJSX(ast);

      case 'text':
        return serializeAsPlainText(ast);

      default: {
        throw new Error(`Unknown serializer: ${serializer}`);
      }
    }
  }, [content, serializer]);

  return <Component className={className}>{serializedContent}</Component>;
};

EditorContent.propTypes = {
  content: PropTypes.shape({ document: PropTypes.string.isRequired }).isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  serializer: PropTypes.oneOf(['jsx', 'text']),
};

EditorContent.defaultProps = {
  className: '',
  component: 'div',
  serializer: 'jsx',
};

export default EditorContent;

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faYelp } from '@fortawesome/free-brands-svg-icons';
import { DateTime } from 'luxon';

import config from 'config';
import useSettings from 'hooks/useSettings';
import withApollo from 'components/withApollo';
import PageLayout from 'components/PageLayout';
import HomeHero from 'components/HomeHero';
import Flex from 'components/Flex';
import TitleGroup from 'components/TitleGroup';
import Special from 'components/Special';
import Event from 'components/Event';
import Button from 'components/Button';
import Link from 'components/Link';
import CircleImage from 'components/CircleImage';
import CuratorWidget from 'components/CuratorWidget';
import EditorContent, { serializeAsPlainText } from 'components/EditorContent';

const QUERY = gql`
  query GetHome($today: String!) {
    allHomePages {
      id
      leadIn
      title
      heroContent
      heroImages {
        images {
          image {
            url: publicUrlTransformed(
              transformation: {
                width: "1440"
                height: "960"
                crop: "fill"
                gravity: "faces:auto"
                format: "auto"
                quality: "auto"
              }
            )
          }
        }
      }

      announcement {
        document
      }

      specialsLeadIn
      specialsTitle
      specialsContent {
        document
      }
      specialsImage {
        url: publicUrlTransformed(
          transformation: {
            width: "800"
            height: "800"
            crop: "fill"
            gravity: "faces:auto"
            format: "auto"
            quality: "auto"
          }
        )
      }

      eventsLeadIn
      eventsTitle
      eventsContent {
        document
      }

      socialLeadIn
      socialTitle
      socialContent {
        document
      }
    }

    allSpecials(where: { status: active }) {
      id
      name
      price
      description
      type
    }

    allEvents(first: 3, orderBy: "startDate", where: { startDate_gte: $today }) {
      id
      name
      description {
        document
      }
      startDate
      image {
        url: publicUrlTransformed(
          transformation: {
            width: "600"
            height: "400"
            crop: "fill"
            gravity: "faces:auto"
            format: "auto"
            quality: "auto"
          }
        )
      }
    }
  }
`;

export default withApollo(() => {
  // eslint-disable-next-line no-unused-vars
  const [_, { facebookUrl, twitterUrl, instagramUrl, yelpUrl }] = useSettings();

  const { data } = useQuery(QUERY, { variables: { today: DateTime.local().toISODate() } });
  const page = data?.allHomePages?.[0];
  const specials = data?.allSpecials ?? [];
  const events = data?.allEvents ?? [];

  const hasAnnouncement = React.useMemo(() => {
    if (!page?.announcement?.document) return false;
    return Boolean(serializeAsPlainText(JSON.parse(page.announcement.document)));
  }, [page]);

  if (!page) return null;

  return (
    <PageLayout title={[page.leadIn, page.title].filter(Boolean).join(' ')}>
      <HomeHero
        leadIn={page.leadIn}
        title={page.title}
        content={page.heroContent}
        images={(page.heroImages?.images ?? []).map((x) => x.image.url)}
      />

      {hasAnnouncement && (
        <section className="container -mt-16 relative z-20">
          <div className="max-w-2xl bg-gray-darker rounded-xl px-6 py-8 md:py-10 md:px-12 mx-auto">
            <EditorContent className="text-lg text-white text-center" content={page.announcement} />
          </div>
        </section>
      )}

      {specials.length > 0 && (
        <section>
          <div className="mt-12 mb-24 container relative">
            <CircleImage
              id="homeSpecials"
              image={page.specialsImage?.url}
              className="hidden lg:block"
            />
            <div className="lg:w-2/3">
              <TitleGroup leadIn={page.specialsLeadIn} title={page.specialsTitle} />
              {page.specialsContent?.document && (
                <EditorContent
                  component="div"
                  className="mt-3 text-lg"
                  content={page.specialsContent}
                />
              )}
              <div className="mt-12">
                <Flex container="-mt-12" item="pt-12">
                  {specials.map((special) => (
                    <Special key={special.id} {...special} />
                  ))}
                </Flex>
              </div>
            </div>
          </div>
        </section>
      )}

      {events.length > 0 && (
        <section className="mt-24 container">
          <TitleGroup leadIn={page.eventsLeadIn} title={page.eventsTitle} />
          {page.eventsContent?.document && (
            <EditorContent component="div" className="mt-3 text-lg" content={page.eventsContent} />
          )}
          <div className="my-8 lg:my-12">
            <Flex
              container="-ml-8 lg:-ml-16 -mt-12 justify-center"
              item="w-full md:w-1/3 pl-8 lg:pl-16 pt-12"
            >
              {events.map((event) => (
                <Link key={event.id} href={`/events?id=${event.id}`}>
                  <Event
                    key={event.id}
                    id={event.id}
                    date={event.startDate}
                    title={event.name}
                    description={event.description}
                    image={event.image?.url}
                  />
                </Link>
              ))}
            </Flex>
          </div>
          <div className="text-center">
            <Button href="/events" className="text-lg">
              View event calendar{' '}
              <FontAwesomeIcon icon={faChevronRight} size="1x" className="ml-3 text-accent" />
            </Button>
          </div>
        </section>
      )}

      <section className="mt-16 container relative">
        <Flex
          container="flex-col lg:flex-row justify-between lg:items-end -ml-6 -mt-6"
          item="pl-6 pt-6"
        >
          <TitleGroup leadIn={page.socialLeadIn} title={page.socialTitle} />

          <Flex container="-ml-3 lg:pb-1" item="pl-3">
            {facebookUrl && (
              <Link
                href={facebookUrl}
                className="w-12 h-12 bg-gray-darker text-white hover:text-gray rounded-full flex justify-center items-center"
              >
                <FontAwesomeIcon icon={faFacebookF} size="lg" />
              </Link>
            )}
            {twitterUrl && (
              <Link
                href={twitterUrl}
                className="w-12 h-12 bg-gray-darker text-white hover:text-gray rounded-full flex justify-center items-center"
              >
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </Link>
            )}
            {instagramUrl && (
              <Link
                href={instagramUrl}
                className="w-12 h-12 bg-gray-darker text-white hover:text-gray rounded-full flex justify-center items-center"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </Link>
            )}
            {yelpUrl && (
              <Link
                href={yelpUrl}
                className="w-12 h-12 bg-gray-darker text-white hover:text-gray rounded-full flex justify-center items-center"
              >
                <FontAwesomeIcon icon={faYelp} size="lg" />
              </Link>
            )}
          </Flex>
        </Flex>

        {page.socialContent?.document && (
          <EditorContent component="div" className="mt-3 text-lg" content={page.socialContent} />
        )}

        <div className="mt-12">
          <CuratorWidget feedId={config('/curator/feedId')} />
        </div>
      </section>
    </PageLayout>
  );
});

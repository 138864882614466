import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import cx from 'classnames';

import EditorContent from './EditorContent';
import Link from './Link';

const formatDate = (date) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);

const Event = ({
  title,
  date,
  description,
  image,
  attachment,
  className,
  layout,
  descriptionLength,
}) => (
  <div
    className={cx(
      'flex flex-col',
      {
        'md:flex-row md:items-start': layout === 'horizontal',
      },
      className
    )}
  >
    <div
      className={cx('rounded-xl overflow-hidden bg-gray-darkest', {
        'md:w-1/3 md:flex-shrink-0': layout === 'horizontal',
      })}
    >
      <img src={image} alt={title} className="block w-full" />
    </div>
    <div
      className={cx('mt-6', {
        'md:mt-0 md:flex-grow md:pl-8': layout === 'horizontal',
      })}
    >
      <p className="text-sm text-white leading-tight mb-3">{formatDate(date)}</p>
      <h3 className="text-xl text-white leading-snug">{title}</h3>
      {description && (
        <EditorContent
          component="p"
          className="mt-3"
          serializer={descriptionLength === 'short' ? 'text' : 'jsx'}
          content={description}
        />
      )}
      {attachment && (
        <p className="mt-4">
          <Link
            href={attachment}
            className="text-accent hover:text-accent-dark transition-colors duration-200"
          >
            Download PDF
          </Link>
        </p>
      )}
    </div>
  </div>
);

Event.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.shape({ document: PropTypes.string }),
  image: PropTypes.string,
  attachment: PropTypes.string,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  descriptionLength: PropTypes.oneOf(['short', 'long']),
};

Event.defaultProps = {
  className: '',
  description: null,
  image: null,
  attachment: null,
  layout: 'vertical',
  descriptionLength: 'short',
};

export default Event;

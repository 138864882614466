import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import style from './CircleImage.module.css';

const CircleImage = ({ image, id, className }) => (
  <div className={cx(style[id], className)}>
    <div
      className={cx('relative bg-cover bg-center rounded-full', style.container, className)}
      style={{ backgroundImage: `url("${image}")` }}
    >
      <div className="absolute inset-0 gradient-circle" />
    </div>
  </div>
);

CircleImage.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.string,
};

CircleImage.defaultProps = {
  id: null,
  className: '',
  image: 'https://source.unsplash.com/random/1000x1000',
};

export default CircleImage;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const TitleGroup = ({ leadIn, title, component: Component, className }) => (
  <hgroup className={cx('', className)}>
    {leadIn && (
      <p className="font-script text-3xl lg:text-4xl text-accent leading-tight">{leadIn}</p>
    )}
    <Component className="text-4xl lg:text-5xl text-white leading-tight">{title}</Component>
  </hgroup>
);

TitleGroup.propTypes = {
  title: PropTypes.node.isRequired,
  leadIn: PropTypes.node,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func }),
  ]),
  className: PropTypes.string,
};

TitleGroup.defaultProps = {
  leadIn: null,
  component: 'h2',
  className: '',
};

export default TitleGroup;

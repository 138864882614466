import React from 'react';

const useCarousel = ({ duration = 8, size }) => {
  const timer = React.useRef(null);
  const [current, setCurrent] = React.useState(0);

  React.useEffect(() => {
    timer.current = setTimeout(() => setCurrent((current + 1) % size), duration * 1000);
    return () => clearTimeout(timer.current);
  }, [current, size, duration]);

  return [
    current,
    {
      advance: React.useCallback(() => setCurrent((current + 1) % size), [
        setCurrent,
        current,
        size,
      ]),
      rewind: React.useCallback(() => setCurrent((current === 0 ? size : current) - 1), [
        setCurrent,
        current,
        size,
      ]),
    },
  ];
};

export default useCarousel;
